import zwitCore from "zw-core";
import axios from "axios";
const BASE_URL = process.env.NODE_ENV === "development" ? "/apis" : "";
//文献管理全部的数据
export function getUserContentMrgList(data) {
  return zwitCore.HTTP.json(
    BASE_URL + "/zw-information/articleBj/nf/getThesisList",
    data,
    false
  );
}
//文献管理属性的数据
export function science() {
  return zwitCore.HTTP.get(
    BASE_URL + "/zw-information/articleUserCore/getUserChannelList",
    {},
    false
  );
}
export function apply(data) {
  return zwitCore.HTTP.get(
    BASE_URL + "/zw-information/articleApplyColumn/getColumnList",
    data,
    false
  );
}
export function resSetStatus(data) {
  return zwitCore.HTTP.json(
    BASE_URL + "/zw-information/articleApplyColumn/applyColumn",
    data,
    false
  );
}
export function resAsyncFamilies(data) {
  return zwitCore.HTTP.json(
    BASE_URL + "/zw-information/articleUserCore/syncArticle",
    data,
    false
  );
}
export function paperList(data) {
  return zwitCore.HTTP.json(
    BASE_URL + "/zw-information/articleUserCore/getUserThesis0List",
    data,
    false
  );
}
export function achievementList(data) {
  return zwitCore.HTTP.json(
    BASE_URL + "/zw-information/articleUserCore/getUserThesis1List",
    data,
    false
  );
}
export function standardList(data) {
  //标准
  return zwitCore.HTTP.json(
    BASE_URL + "/zw-information/articleUserCore/getUserStandardList",
    data,
    false
  );
}
export function PatentsList(data) {
  //专利
  return zwitCore.HTTP.json(
    BASE_URL + "/zw-information/articleUserCore/getUserPatentList",
    data,
    false
  );
}
export function concentrationList(data) {
  //专注
  return zwitCore.HTTP.json(
    BASE_URL + "/zw-information/articleUserCore/getUserMonographList",
    data,
    false
  );
}
export function ResearchList(data) {
  //科技报告
  return zwitCore.HTTP.json(
    BASE_URL + "/zw-information/articleUserCore/getUserTechnologyReportList",
    data,
    false
  );
}

export function restsList(data) {
  //其他
  return zwitCore.HTTP.json(
    BASE_URL + "/zw-information/articleUserCore/getUserThesis4List",
    data,
    false
  );
}
export function getwenzhai(data) {
  return zwitCore.HTTP.json(
    BASE_URL + "/zw-information/articleUserCore/getUserThesis3List",
    data,
    false
  );
}
export function getHYBG(data) {
  return zwitCore.HTTP.json(
    BASE_URL + "/zw-information/articleUserCore/getUserReportList",
    data,
    false
  );
}

export function getUserMessageMrgList(data) {
  return zwitCore.HTTP.json(
    BASE_URL + "/zw-information/articleUserCore/getInformationList",
    data,
    false
  );
}
//获取系统通知列表
export function systemList(data) {
  return zwitCore.HTTP.get(
    BASE_URL + "/zw-user/message/nf/sysMessageList",
    data,
    false
  );
}
export function scholarDiscussList(data) {
  return zwitCore.HTTP.json(
    BASE_URL + "/zw-circle/userCircle/nf/scholarDiscussList",
    data,
    false
  );
}
export function followStudent(data) {
  return zwitCore.HTTP.post(
    BASE_URL + "/zw-user/attention/nf/list",
    data,
    false
  );
}
// 按用户名搜索当前用户发送方列表
export function userMsgList(data) {
  return zwitCore.HTTP.get(
    BASE_URL + "/zw-user/message/nf/searchUserConversation",
    data,
    false
  );
}

export function userEditTypes() {
  return zwitCore.HTTP.get(
    BASE_URL + "/zw-user/scholar/nf/authStatus",
    {},
    false
  );
}
export function subscholar(data) {
  return axios({
    url: `${BASE_URL}/zw-user/scholar/authScholar`,
    method: "post",
    data,
    headers: {
      rsa: "no",
    },
  });
}
export function delDiscuss(data) {
  return zwitCore.HTTP.get(
    BASE_URL + "/zw-circle/userCircle/nf/deleteDiscuss",
    data,
    false
  );
}
//文献管理删除
export function delId(data) {
  return zwitCore.HTTP.json(
    BASE_URL + "/zw-information/articleInfo/deleteArticle",
    data,
    false
  );
}
//隐私设置
export function privacyList() {
  return zwitCore.HTTP.get(
    BASE_URL + "/zw-user/privacy/nf/getPrivacyList",
    {},
    false
  );
}
export function setPrivacy(data) {
  console.log(data);
  return zwitCore.HTTP.get(
    BASE_URL + "/zw-user/privacy/nf/updateUserPrivacy",
    data,
    false
  );
}
//收藏文献管理
export function paperCollectList(data) {
  console.log(data);
  return zwitCore.HTTP.get(
    BASE_URL + "/zw-information/articleUserCore/nf/getUserCollectThesisList",
    data,
    false
  );
}
//收藏资讯管理
export function msgCollectList(data) {
  console.log(data);
  return zwitCore.HTTP.get(
    BASE_URL +
      "/zw-information/articleUserCore/nf/getUserCollectInformationList",
    data,
    false
  );
}
// 获取当前用户指定发送方私信消息列表
export function getLetterList(data) {
  console.log(data);
  return zwitCore.HTTP.get(
    BASE_URL + "/zw-user/message/nf/userConversationMsgList",
    data,
    false
  );
}
// return await axios({
//   url: `${BASE_URL}/zw-information/articleInfo/websave`,
//   method: "post",
//   data,
//   headers: {
//     rsa: "no",
//   },
// });
//  向指定用户发送私信消息
export function senMessage(data) {
  console.log(data);
  return axios({
    url: BASE_URL + "/zw-user/message/nf/sendUserMessage",
    method: "post",
    data,
    headers: {
      rsa: "no",
    },
  });
}

export function getFansList(data) {
  return zwitCore.HTTP.post(
    BASE_URL + "/zw-user/attention/nf/fansList",
    data,
    false
  );
}
export function getFansNoList(data) {
  return zwitCore.HTTP.post(
    BASE_URL + "/zw-user/attention/nf/fansNoList",
    data,
    false
  );
}
export function focusScholarApi(data) {
  return zwitCore.HTTP.get(
    BASE_URL + "/zw-user/attention/nf/follow",
    data,
    false
  );
}
export function unfocusScholarApi(data) {
  return zwitCore.HTTP.get(
    BASE_URL + "/zw-user/attention/nf/unfollow",
    data,
    false
  );
}
export function isAttention(data) {
  return zwitCore.HTTP.get(
    BASE_URL + "/zw-user/attention/nf/isAttention",
    data,
    false
  );
}
export function SpecifiedList(data) { // 指定用户会话
  return zwitCore.HTTP.get(
    BASE_URL + "/zw-user/message/nf/userConversation",
    data,
    false
  );
}
export function getUserInfo() {
  return zwitCore.HTTP.get(BASE_URL + "/zw-user/nf/getloginInfo", {}, false);
}
