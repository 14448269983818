<template>
  <div id="collect">
    <TopList :navList="navList" @setType="setType" :type="type"></TopList>
    <Msg v-if="type === 0"></Msg>
    <Paper v-if="type === 1"></Paper>
  </div>
</template>

<script>
import TopList from "../../components/TopList";
import Msg from "./components/msg";
import Paper from "./components/paper";

export default {
  name: "index",
  components: {
    TopList,
    Msg,
    Paper,
  },
  methods: {
    setType(e) {
      this.type = e;
      this.name = this.navList[e];
    },
  },
  data() {
    return {
      type: 0,
      name: "",
      navList: ["资讯", "文献"],
    };
  },
};
</script>

<style scoped>
#collect {
  min-height: 800px;
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 10px;
}
</style>
