<template>
  <div class="msg">
    <div class="classify">
      <div class="left">
        <span class="status">状态：</span>
        <span>文章</span>
        <!--        <span-->
        <!--          @click="setStatusAct(1, '已审核')"-->
        <!--          :class="statusAct === 1 ? 'act' : 'statusList'"-->
        <!--        >已审核</span-->
        <!--        >-->
        <!--        <span-->
        <!--            @click="setStatusAct(1, 0)"-->
        <!--            :class="statusAct === 1 ? 'act' : 'statusList'"-->
        <!--        >期刊论文</span-->
        <!--        >-->
        <!--        <span-->
        <!--            @click="setStatusAct(2, 120)"-->
        <!--            :class="statusAct === 2 ? 'act' : 'statusList'"-->
        <!--        >会议成果</span-->
        <!--        >-->
        <!--        <span-->
        <!--            @click="setStatusAct(3, 1)"-->
        <!--            :class="statusAct === 3 ? 'act' : 'statusList'"-->
        <!--        >专利</span-->
        <!--        >-->
        <!--        <span-->
        <!--            @click="setStatusAct(4, 2)"-->
        <!--            :class="statusAct === 4 ? 'act' : 'statusList'"-->
        <!--        >专著</span-->
        <!--        >-->
        <!--        <span-->
        <!--            @click="setStatusAct(5, 3)"-->
        <!--            :class="statusAct === 5 ? 'act' : 'statusList'"-->
        <!--        >科技报告</span-->
        <!--        >-->
        <!--        <span-->
        <!--            @click="setStatusAct(6, 4)"-->
        <!--            :class="statusAct === 6 ? 'act' : 'statusList'"-->
        <!--        >标准</span-->
        <!--        >-->
        <!--        <span-->
        <!--            @click="setStatusAct(7, 40)"-->
        <!--            :class="statusAct === 7 ? 'act' : 'statusList'"-->
        <!--        >其它</span-->
        <!--        >-->
      </div>
    </div>
    <div class="list">
      <Spin size="large" fix v-show="spinShow"></Spin>
      <div class="box" v-for="item in list" :key="item.articleId">
        <div class="left" v-if="item.cover">
          <img v-lazy="`${$global.imgUrl}${item.cover}`" alt="" />
        </div>
        <div :style="{ marginLeft: item.cover ? '27px' : '0px' }" class="right">
          <div class="title" @click="goDetails(item)">{{ item.title }}</div>
          <div class="middle">{{ item.artSummary }}</div>
          <IconButton :every="item" :curcer="item.articleId" :isUser="false"></IconButton>
        </div>
      </div>
      <div class="no-results" v-show="total === 0 && !spinShow">
        <img :src="require('@/assets/qietu/results.png')" alt="" />
        <p>暂无收藏</p>
      </div>
      <Paging :total="total" :curPage="page" :limit="5" :showPage="5" @changePager="handlePager"></Paging>
    </div>
  </div>
</template>

<script>
import { msgCollectList } from "../../../../../apis/user";
import IconButton from "@/components/pager/IconButton.vue";
import Paging from "@/components/paging/index.vue";

export default {
  name: "paper",
  data() {
    return {
      statusAct: 0,
      total: 0,
      page: 1,
      list: [],
      spinShow: false,
    };
  },
  components: {
    IconButton,
    Paging,
  },
  created() {
    this.getList();
  },
  filters: {
    type: function (atype) {
      if (atype === 0) return `期刊论文`;
      if (atype === 1) return `专利`;
      if (atype === 2) return `专著`;
      if (atype === 3) return `科技报告`;
      if (atype === 4) return `标准`;
    },
  },
  methods: {
    handlePager(i) {
      this.page = i;
      this.getList();
    },
    async getList() {
      let obj = {
        pageNo: this.page,
        pageSize: 5,
      };
      this.spinShow = true;
      let { data } = await msgCollectList(obj);
      let { total } = data;
      this.total = Number(total);
      this.list = data.list;
      scrollTo(0, 0);
      this.spinShow = false;
    },
    goDetails(item) {
      let url;
      url = this.$router.resolve({
        path: "/informationdetail",
        query: {
          articleType: item.articleType,
          id: item.articleId,
        },
      });
      window.open(url.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.msg {
  margin-left: 40px;
  margin-right: 40px;

  .classify {
    display: flex;
    height: 30px;
    justify-content: space-between;
    width: 100%;

    .searchinput {
      width: 260px;
      border-radius: 10px;
    }

    .right {
      height: 30px;

      .time {
        //background: yellow;
        line-height: 30px;
        margin-right: 5px;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }

    .left {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      cursor: pointer;

      .status {
        margin-right: 3px;
      }

      .statusList {
        margin-left: 10px;
        margin-right: 10px;
      }

      .act {
        margin-left: 10px;
        margin-right: 10px;
        color: #00a4ff;
      }
    }
  }

  .list {
    position: relative;
    margin-top: 30px;

    //.box:hover .title {
    //  color: #00a4ff;
    //}
    //.box:hover .btn1 {
    //  display: none;
    //}
    //.box:hover .btn2 {
    //  display: block;
    //}
    //.box:hover{
    //  background: pink;
    //}
    .box:hover .right .title {
      color: #00a4ff;
    }

    .box:hover .bottom .btn1 {
      display: none;
    }

    .box:hover .bottom .btn2 {
      display: block;
      //background: #00A4FF;
    }

    //.box:hover .title .titleSon {
    //  display: inline-block;
    //  background: #00a4ff;
    //  color: #fff;
    //}
    .box {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      border-top: 1px dashed #d6d6d6;
      padding: 30px 0;

      .right {
        .title {
          margin-bottom: 10px;
          cursor: pointer;
          font-size: 20px;
          font-family: Source Han Sans SC;
          color: #333333;
          white-space: nowrap;
        }

        .bottom {
          display: flex;
        }

        .middle {
          margin-bottom: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        .btn1 {
          margin-right: 15px;
          display: block;
        }

        .btn2 {
          margin-right: 15px;
          display: none;
        }

        img {
          //width: 69px;
          height: 26px;
        }
      }

      .left {

        //float: left;
        img {
          width: 200px;
          height: 135px;
        }
      }
    }

    .no-results {
      margin: 0 auto;
      width: 500px;

      // height: 300px;
      img {
        width: 100%;
      }

      p {
        text-align: center;
        font-size: 16px;
        color: #999999;
      }
    }
  }
}</style>
