<template>
  <!-- <div class="paging-container" v-if="allPage > 1"> -->
    <!-- <div
      class="hide-page"
      title="首页"
      @click="changePage(1)"
      :class="{ disable: curPage === 1 }"
    >
      |&lt;&lt;
    </div>
    <div
      class="left-page"
      :class="{ disable: curPage === 1 }"
      @click="changePage(curPage - 1)"
      title="上一页"
    >
      &lt;
    </div>
    <div
      class="number"
      v-for="(v, i) in centerNumber"
      :key="i"
      :class="{ active: curPage === v }"
      @click="changePage(v)"
      :title="v"
    >
      {{ v }}
    </div>
    <div
      class="right-page"
      :class="{ disable: curPage === allPage }"
      @click="changePage(curPage + 1)"
      title="下一页"
    >
      &gt;
    </div>
    <div
      class="hide-page"
      title="尾页"
      @click="changePage(allPage)"
      :class="{ disable: curPage === allPage }"
    >
      &gt;&gt;|
    </div>
    <span class="totalPage">{{ curPage }}/{{ allPage }}</span> -->
    <Page
      v-show="total > limit"
      :total="total"
      :current="curPage"
      show-total
      :page-size="limit" 
      :size="showMini"
      :styles="{textAlign: 'center',marginTop: '60px',marginBottom: '50px'}"
      @on-change="pageChange">
    </Page>
  <!-- </div> -->
</template>

<script>
export default {
  props: {
    curPage: {
      type: Number,
      default: 1,
    },
    // 总数量
    total: {
      type: Number,
      default: 0,
    },
    // 页容量
    limit: {
      type: Number,
      default: 10,
    },
    // 分页器是否是迷你版
    showMini : {
      type: String,
    },
    // 显示的页数
    showPage: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    allPage() {
      return Math.ceil(this.total / this.limit);
    },
    minPage() {
      let min = this.curPage - Math.floor(this.showPage / 2);
      if (min < 1) {
        min = 1;
      }
      return min;
    },
    maxPage() {
      let max = this.minPage + this.showPage - 1;
      if (max > this.allPage) {
        max = this.allPage;
      }
      return max;
    },
    centerNumber() {
      let arr = [];
      for (let i = this.minPage; i <= this.maxPage; i++) {
        arr.push(i);
      }
      return arr;
    },
  },
  methods: {
    pageChange(page) {
      this.$emit("changePager", page);
    },
    changePage(newPage) {
      if (newPage < 1) {
        newPage = 1;
      }
      if (newPage > this.allPage) {
        newPage = this.allPage;
      }
      if (newPage === this.curPage) {
        return;
      }
      this.$emit("changePager", newPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.paging-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
  font-size: 14px;
  color: #333;
  user-select: none;
  overflow: hidden;
  .right-page,
  .left-page,
  .number {
    width: 40px;
    height: 30px;
    border: 1px solid #ddd;
    border-radius: 10px;
    text-align: center;
    margin: 0 3px;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
    &:hover {
      border: 1px solid #008c8c;
      color: #008c8c;
    }
    &.active {
      border: 1px solid #008c8c;
      color: #008c8c;
    }
    &.disable {
      color: rgb(148, 148, 148);
      border: 1px solid rgb(148, 148, 148);
      cursor: not-allowed;
    }
  }
  .totalPage {
    font-size: 18px;
    width: 60px;
    margin-left: 10px;
  }
  .hide-page {
    border: none;
    color: rgb(148, 148, 148);
    &:hover {
      border: none;
      color: #008c8c;
    }
    &.disable {
      cursor: not-allowed;
    }
  }
}
</style>
