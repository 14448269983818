<template>
  <div class="TopList">
    <span
      v-for="(item, i) in navList"
      :key="i"
      @click="setAct(i)"
      :class="['nav', act === i ? 'act' : '']"
    >{{item}}</span>
  </div>
</template>

<script>
export default {
  name: "TopList",
  props: {
    navList: {
      type: Array,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      act: 0,
    };
  },
  methods: {
    setAct(num) {
      this.act = num;
      this.$emit("setType", num);
    },
  },
};
</script>

<style lang="scss" scoped>
.TopList {
  margin: 20px 40px 30px;
  // padding-bottom: 39px;
  // border-bottom: 1px solid #e6e6e6;
  // height: 46px;
  .nav {
    display: inline-block;
    width: 125px;
    height: 48px;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px solid #e6e6e6;
    font-size: 18px;
    color: #333333;
    cursor: pointer;
  }
  .act::after {
    content: "";
    display: block;
    width: 125px;
    height: 8px;
    background-image: url("~@/assets/imgs/xz.png");
    background-size: 100% 100%;
  }
  .act {
    color: #00A4FF;
  }
}
</style>
