<template>
  <div class="paper">
    <div class="classify">
      <div class="left">
        <span class="status">状态：</span>
        <span
          @click="setStatusAct(0, 11)"
          :class="statusAct === 0 ? 'act' : 'statusList'"
          >全部</span
        >
        <!--        <span-->
        <!--          @click="setStatusAct(1, '已审核')"-->
        <!--          :class="statusAct === 1 ? 'act' : 'statusList'"-->
        <!--        >已审核</span-->
        <!--        >-->
        <span
          @click="setStatusAct(1, 0)"
          :class="statusAct === 1 ? 'act' : 'statusList'"
          >期刊论文</span
        >
        <span
          @click="setStatusAct(2, 120)"
          :class="statusAct === 2 ? 'act' : 'statusList'"
          >会议成果</span
        >
        <span
          @click="setStatusAct(3, 1)"
          :class="statusAct === 3 ? 'act' : 'statusList'"
          >专利</span
        >
        <span
          @click="setStatusAct(4, 2)"
          :class="statusAct === 4 ? 'act' : 'statusList'"
          >专著</span
        >
        <span
          @click="setStatusAct(5, 3)"
          :class="statusAct === 5 ? 'act' : 'statusList'"
          >科技报告</span
        >
        <span
          @click="setStatusAct(6, 4)"
          :class="statusAct === 6 ? 'act' : 'statusList'"
          >标准</span
        >
        <span
          @click="setStatusAct(7, 40)"
          :class="statusAct === 7 ? 'act' : 'statusList'"
          >其它</span
        >
      </div>
    </div>
    <div class="list">
      <Spin size="large" fix v-show="spinShow"></Spin>
      <div class="box" v-for="item in list" :key="item.articleId">
        <div class="title" @click="goDetails(item)">
          <span v-if="item.articleTypeName">[{{ item.articleTypeName }}]</span>
          <span v-else-if="item.articleType == 3">[科技报告]</span>
          <span v-else-if="item.articleType == 4">[标准]</span>
          {{ item.title }}
          <span v-if="item.type && item.type !== 4" class="titleSon">{{
            item.type === 1 ? "会议论文" : item.type === 2 ? "会议PPT" : ""
          }}</span>
        </div>
        <div class="tips">
          <span v-if="item.mettingName">{{ item.mettingName }}-</span
          ><span v-if="item.mettingTime">{{ item.mettingTime }}</span>
          &nbsp;&nbsp;<span v-if="item.authorList">{{ item.authorList }}</span>
        </div>
        <IconButton :every="item" :curcer="item.articleId"></IconButton>
      </div>
      <div class="no-results" v-show="total === 0 && !spinShow">
        <img :src="require('@/assets/qietu/results.png')" alt="" />
        <p>暂无收藏</p>
      </div>
      <Paging
        :total="total"
        :curPage="page"
        :limit="10"
        :showPage="5"
        @changePager="handlePager"
      ></Paging>
    </div>
  </div>
</template>

<script>
import { paperCollectList } from "../../../../../apis/user";
import IconButton from "@/components/pager/IconButton.vue";
import Paging from "@/components/paging/index.vue";
export default {
  name: "paper",
  components: {
    IconButton,
    Paging,
  },
  data() {
    return {
      statusAct: 0,
      page: 1,
      total: 0,
      status: 11,
      list: [],
      spinShow: false,
    };
  },
  created() {
    this.getList();
  },
  filters: {
    type: function (atype) {
      if (atype === 0) return `期刊论文`;
      // if(atype===0 && type ==0) return `期刊论文`
      // if(atype===0 && type ==1) return `会议成果`
      // if(atype===0 && type ==2) return `会议PPT`
      // if(atype===0 && type ==4) return `其他`
      if (atype === 1) return `专利`;
      if (atype === 2) return `专著`;
      if (atype === 3) return `科技报告`;
      if (atype === 4) return `标准`;
    },
  },
  methods: {
    handlePager(i) {
      this.page = i;
      this.getList();
    },
    async getList() {
      let obj = {
        pageNo: this.page,
        pageSize: 10,
        articleType: this.status,
      };
      this.spinShow = true;
      let { data } = await paperCollectList(obj);
      let { total } = data;
      this.total = Number(total);
      this.list = data.list;
      scrollTo(0, 0);
      this.spinShow = false;
    },
    setStatusAct(i, status) {
      this.statusAct = i;
      this.status = status;
      this.page = 1;
      this.getList();
    },
    goDetails(item) {
      let url;
      if (item.articleType === 0) {
        if (item.type === 0) {
          url = this.$router.resolve({
            path: "/paperDetail",
            query: {
              articleType: item.articleType,
              articleId: item.articleId,
            },
          });
        } else if (item.type === 1) {
          url = this.$router.resolve({
            path: "/conferencepapers",
            query: {
              articleType: item.articleType,
              articleId: item.articleId,
            },
          });
        } else if (item.type === 2) {
          url = this.$router.resolve({
            path: "/conferenceppt",
            query: {
              articleType: item.articleType,
              articleId: item.articleId,
            },
          });
        } else if (item.type === 4) {
          url = this.$router.resolve({
            path: "/conferenceother",
            query: {
              articleType: item.articleType,
              articleId: item.articleId,
            },
          });
        }
      } else if (item.articleType === 1) {
        url = this.$router.resolve({
          path: "/patentdetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      } else if (item.articleType === 2) {
        url = this.$router.resolve({
          path: "/monographdetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      } else if (item.articleType === 3) {
        url = this.$router.resolve({
          path: "/reportdetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      } else if (item.articleType === 4) {
        url = this.$router.resolve({
          path: "/standarddetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      } else if (item.articleType === 5 && item.type === 0) {
        url = this.$router.resolve({
          path: "/informationdetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      }
      window.open(url.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.paper {
  margin-left: 40px;
  margin-right: 40px;

  .classify {
    display: flex;
    height: 30px;
    justify-content: space-between;
    width: 100%;

    .searchinput {
      width: 260px;
      border-radius: 10px;
    }

    .right {
      height: 30px;

      .time {
        //background: yellow;
        line-height: 30px;
        margin-right: 5px;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }

    .left {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      cursor: pointer;

      .status {
        margin-right: 3px;
      }

      .statusList {
        margin-left: 10px;
        margin-right: 10px;
      }

      .act {
        margin-left: 10px;
        margin-right: 10px;
        color: #00a4ff;
      }
    }
  }

  .list {
    position: relative;
    margin-top: 30px;
    .box:hover .title {
      color: #00a4ff;
    }
    .box:hover .btn1 {
      display: none;
    }
    .box:hover .btn2 {
      display: block;
      //background: #00A4FF;
    }
    .box:hover .title .titleSon {
      display: inline-block;
      background: #00a4ff;
      color: #fff;
    }
    .box {
      border-top: 1px dashed #d6d6d6;
      padding-bottom: 30px;
      .title {
        cursor: pointer;
        margin-top: 30px;
        font-size: 20px;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #999999;
        white-space: nowrap;
        .titleSon {
          color: #00a4ff;
          text-align: center;
          line-height: 20px;
          display: inline-block;
          width: 68px;
          height: 20px;
          font-size: 12px;
          border: 1px solid #00a4ff;
          border-radius: 6px;
        }
      }
      .btn1 {
        display: block;
      }
      .btn2 {
        display: none;
      }
      .tips {
        margin-top: 8px;
        font-weight: 400;
        color: #999999;
        font-size: 14px;
      }
      .bottom {
        margin-top: 20px;
        display: flex;
        .btn1 {
          margin-right: 15px;
          //width: 69px;
          //height: 26px;
          //border: 1px solid #e6e6e6;
        }
        .btn2 {
          margin-right: 15px;
          //width: 69px;
          //height: 26px;
          //border: 1px solid #e6e6e6;
        }
        img {
          //width: 69px;
          height: 26px;
        }
      }
    }
    .no-results {
      margin: 0 auto;
      width: 500px;
      // height: 300px;
      img {
        width: 100%;
      }
      p {
        text-align: center;
        font-size: 16px;
        color: #999999;
      }
    }
  }
}
</style>
